.squares-items {
  @apply grid grid-cols-2 sm:mt-40;

  &.square-items--narrow {
    @apply w-full lg:w-2/3 mx-auto border-r border-l border-pageBGDark;
  }
}

.squares-items + .squares-items {
  @apply border-b;
}

.square-item {
  @apply bg-pageBG flex col-span-2 md:col-span-1;

  &.square-item--secondary {
    @apply bg-pageSecondary text-pageText;
  }

  &.square-item--highlight {
    @apply bg-pageHighlight text-pageText;
  }

  &.square-item--dark {
    @apply bg-pageDark;
  }

  &.square-item--primary {
    @apply bg-pagePrimary text-pageBG;
  }
}

.square-item--wrapper {
  @apply relative flex w-full flex-1 flex-col sm:p-10;

  .square-items--narrow & {
    @apply p-5;
  }
}

.square-item--details {
  @apply max-w-prose mt-3 opacity-100 transition-opacity duration-300;
  .square-item--primary &,
  .square-item--dark & {
    @apply text-pageBG;
  }
  .rc p {
    @apply text-sm;
  }
}

.square-item--content {
  @apply relative max-w-2xl p-4 sm:px-10;

  .square-items--narrow & {
    @apply p-0 sm:px-5;
  }
}

.square-item--photo {
  @apply relative min-h-500 sm:min-h-800;
}

.square-item--photo-wrap {
  @apply relative;
}

.square-item--product-wrap {
  @apply relative w-2/3 mx-auto max-w-xl;
}

.square-item--caption {
  @apply absolute block top-12 left-auto;
  width: 20rem;

  span {
    @apply absolute block left-0 md:left-5 lg:left-6 origin-top-left text-sm transform rotate-90 mix-blend-difference text-white;

    .square-item--primary & {
      @apply text-pageText mix-blend-normal;
    }
  }
}

.square-item--link {
  @apply absolute top-0 right-0 left-0 bottom-0 bg-opacity-25 bg-white text-center opacity-0 hover:opacity-100 text-5xl;
  padding-top: 50%;
  backdrop-filter: blur(6px);
  transition: opacity 0.2s linear;
}

.square-item--emphasise {
  .square-item--wrapper {
    @apply sm:p-0;
  }

  .square-item--photo-wrap {
    @apply h-full;
  }

  .square-item--photo {
    @apply pt-0 h-full;
  }

  .square-item--details {
    @apply absolute bottom-3 right-0 bg-white bg-opacity-80 p-3;

    /* @apply absolute bottom-3 right-3 mix-blend-difference text-white; */
  }

  .square-item--details {
    .square-item:hover & {
      @apply opacity-0;
    }

    .square-item:hover &:hover {
      @apply opacity-100;
    }
  }
}

/* .squares:hover .card--dot {
  transform: scale(1);
}
.card--dot {
  position: relative;
  height: 0;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(240, 81, 81, var(--tw-bg-opacity));
  background: linear-gradient(0, blue, blue 50%, white 50%);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  padding-top: 100%;
  transform: scale(0.1);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.squares:hover .card--dot span {
  opacity: 1;
}
.card--dot span {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600;
  opacity: 0;
  transform: translateY(-50%);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
} */
