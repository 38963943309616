.header {
  @apply sticky top-0 inset-x-0 z-50;

  background-color: #e7e5d6c2;
  backdrop-filter: blur(6px);

  &.is-overlay {
    @apply h-0;
  }

  &--outer {
    @apply relative;
  }

  &--inner {
    @apply relative z-20;

    /* background: linear-gradient(360deg, #efc46c00, #a10ba338); */

    .is-overlay & {
      @apply bg-transparent transition-colors duration-300;
    }

    .has-bg & {
      background: linear-gradient(360deg, #efc46c00, #a10ba338);
    }

    .is-white & {
      @apply text-white delay-75;
    }
  }

  &--content {
    @apply flex flex-col items-center relative z-30;
  }

  &--border {
    @apply absolute inset-x-0 bottom-0 z-20 border-t;
    transition: opacity 0s 0.6s linear;

    .is-white & {
      @apply border-white;
      transition-duration: 0.2s;
      transition-delay: 0s;
      /* &:not(.is-hidden) {
        transition-delay: 0s;
      } */
    }

    &.is-hidden {
      @apply opacity-0;
      transition-delay: 0s;
    }
  }

  &--observer {
    @apply absolute top-8;
  }

  &--logo {
    @apply flex flex-col relative z-10 py-5;
  }

  &--logo-link {
    @apply block;

    svg {
      @apply block fill-current w-auto h-9 sm:h-12 transition-all duration-300;
      .has-bg & {
        @apply h-7 sm:h-8;
      }
    }
  }

  .menu-toggle {
    @apply sm:hidden absolute inset-y-0 left-0 z-60 clean-btn bg-transparent;

    .is-overlay:not(.has-bg) & {
      @apply transition-colors duration-300;
    }

    &.is-open {
      @apply text-pageText;
    }
  }

  .is-open {
    .hamburger {
      &::before,
      &::after {
        @apply opacity-0;
        transition: opacity 0s 0.2s linear, transform 0.2s ease-out;
      }

      &::before {
        transform: translateY(5px);
      }

      &::after {
        transform: translateY(-5px);
      }

      &--icon {
        @apply border-transparent;

        &::before,
        &::after {
          @apply opacity-100;
          transition: transform 0.2s 0.2s ease-out;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .hamburger {
    @apply relative flex flex-col items-center justify-center mx-auto w-7;

    &::before,
    &::after {
      @apply block relative w-full h-0 border-t border-pageText;
      content: '';
      margin: 6px 0;
      transition: opacity 0s 0.2s linear, transform 0.2s 0.2s ease-out;

      .is-open & {
        @apply opacity-0;
        transition: opacity 0s 0.2s linear, transform 0.2s ease-out;
      }
    }

    &::before {
      .is-open & {
        transform: translateY(5px);
      }
    }

    &::after {
      .is-open & {
        transform: translateY(-5px);
      }
    }

    &--icon {
      @apply block relative w-full h-0 border-t border-pageText;
      transition: border 0s 0.2s linear;

      .is-open & {
        @apply border-transparent;
      }

      &::before,
      &::after {
        @apply block absolute w-full h-0 border-t opacity-0;
        content: '';
        top: calc(50% - 0.5px);
        transition: opacity 0s 0.2s, transform 0.2s ease-out;

        .is-open & {
          @apply opacity-100;
          transition: transform 0.2s 0.2s ease-out;
        }
      }

      &::before {
        .is-open & {
          transform: rotate(45deg);
        }
      }

      &::after {
        .is-open & {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .cart-toggle {
    @apply font-bold clean-btn flex items-center relative bg-transparent -mr-2 sm:mr-1 sm:p-2;

    &--count {
      @apply text-sm;
    }
  }

  .main-navigation {
    &--desktop {
      @apply hidden sm:flex justify-between items-stretch absolute inset-0;

      .menu-left,
      .menu-right {
        @apply flex items-center;
      }
      .menu-right {
        @apply px-5;
      }

      ul {
        @apply flex;

        li {
          @apply mx-2;
        }

        button {
          @apply clean-btn bg-transparent;
          font: inherit;
        }

        a,
        button {
          @apply block p-2 font-bold  font-display;
        }
      }
    }

    &--mobile {
      @apply sm:hidden flex justify-end items-center absolute inset-0;

      .menu-mobile {
        @apply fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-pageBG text-pageText;
        height: calc(var(--vh, 1vh) * 100);
        max-width: 420px;
        will-change: transform;

        @media (min-width: 421px) {
          @apply border-r;
        }

        &--backdrop {
          @apply fixed inset-0 z-40 bg-black bg-opacity-40 pointer-events-none opacity-0;
          transition: opacity 0.15s linear;

          &.is-active {
            @apply pointer-events-auto opacity-100;
            backdrop-filter: blur(6px);
          }
        }

        &--inner {
          @apply flex-1 flex flex-col overflow-y-scroll px-4 pb-4 bg-pageBG;
          padding-top: calc(var(--headerHeight, 10rem) + 1.5rem);
        }

        button {
          @apply clean-btn bg-transparent;
          font: inherit;
        }

        a,
        button {
          @apply block p-0;
        }

        &--primary {
          ul {
            @apply flex flex-col mb-4;
          }

          a,
          button {
            @apply text-2xl leading-normal;
          }

          .dropdown--nav {
            a,
            button {
              @apply text-lg;
            }
          }
        }

        &--secondary {
          @apply pt-8;

          ul {
            @apply flex flex-col;
          }

          a,
          button {
            @apply text-xl leading-normal;
          }
        }

        &--featured {
          @apply flex;
        }
      }
    }
  }
}

a[href='#content'].skip-link {
  @apply block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs;

  &:focus {
    @apply translate-y-0 outline-none;
  }
}
