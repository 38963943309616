.side-by-side {
  @apply grid grid-cols-12 gap-0 py-20;

  &__image {
    .has-block-angles & {
      box-shadow: 0px 0px 3px 1px #0000004d;
    }
  }

  &__content {
    @apply bg-pageBG;

    .has-block-angles & {
      @apply bg-transparent;
    }
  }

  &.has-block-angles {
    @apply bg-transparent;
  }
}
